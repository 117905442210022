@keyframes rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.rcf-loader-container {
  text-align: center;
  place-content: center;
  align-items: center;
  display: flex;
}

.rcf-loader {
  -ms-animation: rotating 1s linear infinite;
  animation: 1s linear infinite rotating;
}

.slide-display {
  flex-wrap: nowrap;
  width: 99.5%;
  display: flex;
  overflow: auto;
}

.slide-display-info {
  flex: none;
  width: 100%;
}

.slide-display-button {
  flex-wrap: nowrap;
  flex: none;
  height: 100%;
  overflow: auto;
}

.scanner-lens {
  z-index: 103;
  border: 3px solid #ffffff80;
  border-radius: 5px;
  width: 90%;
  height: 40%;
  position: absolute;
  box-shadow: 0 0 10vh #000000e6;
}

.scanner-container {
  z-index: 101;
  background: #fff;
  place-items: center;
  min-width: 100%;
  min-height: 100%;
  display: grid;
  position: fixed;
  inset: 0;
  overflow: visible !important;
}

.scanner-target {
  z-index: 102;
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  inset: 0;
  overflow: visible !important;
}

.scanner-close {
  z-index: 103;
  position: fixed;
  top: 70px;
  right: 25px;
}

.scanner-button {
  z-index: 103;
  position: fixed;
  bottom: 50px;
  left: 0;
}

video {
  min-width: 100%;
  min-height: 100%;
  object-fit: fill !important;
  overflow: visible !important;
}

html, body {
  scroll-behavior: smooth;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.navbar {
  color: #111;
  white-space: nowrap;
  word-wrap: unset;
  z-index: 100;
  background: #e5e5e5;
  justify-content: space-evenly;
  height: 66px;
  transition: all .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  overflow: visible hidden;
  box-shadow: 0 0 7px #111;
}

.navbar svg {
  height: 48px;
}

.navbar:focus {
  outline: none;
}

.navbar a {
  color: #333;
}

.navbar a:hover, .navbar a:active {
  color: #777;
}

.slider-container {
  width: fit-content;
  margin-left: auto;
}

.text-color-processing {
  color: #8e8d8d;
}

.file-upload {
  border: 1px solid #a9a8a8;
  padding: 4px;
}

.file-upload:hover {
  border: 1px solid #252525;
}
/*# sourceMappingURL=index.4956208a.css.map */
