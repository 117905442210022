html,body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.navbar {
  background: #e5e5e5;
  bottom: 0;
  box-shadow: 0px 0px 7px #111111;
  color: #111111;
  display: flex;
  height: 66px;
  justify-content: space-evenly;
  left: 0;
  overflow-x: visible;
  overflow-y: hidden;
  position: fixed;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  word-wrap: unset;
  z-index: 100;
}
.navbar svg {
  height: 48px;
}
.navbar:focus {
  outline: none
}
.navbar a {
  color: #333333;
}
.navbar a:hover, .navbar a:active {
  color: #777777;
}
