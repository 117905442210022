.text-color-processing{
    color: #8e8d8d;
}

.file-upload{
    border: solid 1px rgb(169, 168, 168);
    padding: 4px;
}

.file-upload:hover{
    border: solid 1px rgb(37, 37, 37);
}