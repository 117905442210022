.slide-display {
  width: 99.5%;
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
}

.slide-display-info {
  width: 100%;
  flex: 0 0 auto;
}

.slide-display-button {
  flex-wrap: nowrap;
  overflow: auto;
  height: 100%;
  flex: 0 0 auto;
}
