.scanner-lens {
	position: absolute;
	width: 90%;
	border: 3px solid rgba(255, 255, 255, 0.5);
	box-shadow: 0px 0px 10vh rgba(0, 0, 0, 0.9);
	height: 40%;
	border-radius: 5px;
	z-index: 103;
}

.scanner-container {
	background: white;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	min-height: 100%;
	min-width: 100%;
	overflow: visible !important;
	overflow-x: visible !important;
	overflow-y: visible !important;
	z-index: 101;
	display: grid;
	place-items: center;
}
.scanner-target {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	min-height: 100%;
	min-width: 100%;
	overflow: visible !important;
	overflow-x: visible !important;
	overflow-y: visible !important;
	z-index: 102;
}
.scanner-close {
	top: 70px;
	right: 25px;
	position: fixed;
	z-index: 103;
}
.scanner-button {
	bottom: 50px;
	left: 0;
	position: fixed;
	z-index: 103;
}

video {
	min-height: 100%;
	min-width: 100%;
	overflow: visible !important;
	overflow-x: visible !important;
	overflow-y: visible !important;
	object-fit: fill !important;
}
